<script lang="ts">import Main from "./Main/Main.svelte";
import Side from "./Side/Side.svelte";
let gtag;
let hideSide;
let onMenuClick = function () {
    gtag && gtag("event", "click", {
        event_category: "sidenav",
        event_label: "open sidenav",
        value: "open",
    });
    hideSide = !hideSide;
};
let onSideHide = function () {
    hideSide = true;
};
let closeSide = function () {
    hideSide = true;
};
</script>

<style type="text/scss" global>
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap");:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6) {
  font-weight: inherit;
}:global(html),
:global(body),
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6),
:global(a),
:global(p),
:global(span),
:global(em),
:global(small),
:global(strong),
:global(sub),
:global(sup),
:global(mark),
:global(del),
:global(ins),
:global(strike),
:global(abbr),
:global(dfn),
:global(blockquote),
:global(q),
:global(cite),
:global(code),
:global(pre),
:global(ol),
:global(ul),
:global(li),
:global(dl),
:global(dt),
:global(dd),
:global(div),
:global(section),
:global(article),
:global(main),
:global(aside),
:global(nav),
:global(header),
:global(hgroup),
:global(footer),
:global(img),
:global(figure),
:global(figcaption),
:global(address),
:global(time),
:global(audio),
:global(video),
:global(canvas),
:global(iframe),
:global(details),
:global(summary),
:global(fieldset),
:global(form),
:global(label),
:global(legend),
:global(table),
:global(caption),
:global(tbody),
:global(tfoot),
:global(thead),
:global(tr),
:global(th),
:global(td),
:global(button) {
  margin: 0;
  padding: 0;
  border: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
}:global(a) {
  color: inherit;
  text-decoration: none;
}:global(article),
:global(aside),
:global(footer),
:global(header),
:global(nav),
:global(section),
:global(main) {
  display: block;
}:global(*) {
  box-sizing: border-box;
}:global(*:before),
:global(*:after) {
  box-sizing: inherit;
}:global(table) {
  border-collapse: collapse;
  border-spacing: 0;
}:global(ol),
:global(ul) {
  list-style: none;
}:global(img),
:global(video) {
  max-width: 100%;
}:global(img) {
  border-style: none;
}:global(blockquote),
:global(q) {
  quotes: none;
}:global(blockquote:after),
:global(blockquote:before),
:global(q:after),
:global(q:before) {
  content: "";
  content: none;
}:global([hidden]) {
  display: none !important;
}:global([disabled]) {
  cursor: not-allowed;
}:global(:focus:not(:focus-visible)) {
  outline: none;
}/**
  const SMALL = 425;
  const MEDIUM = 768;
  const LARGE = 1200;
*/:global(.form) {
  max-width: 425px;
  min-width: 288px;
  padding: 30px;
  margin: 0 1rem;
}@media (min-width: 769px) {
  :global(.form) {
    padding: 40px;
  }
}:global(.form__input) {
  outline: none;
  appearance: none;
  border: 1px solid;
  border-radius: 0.25rem;
  width: 100%;
  padding: 10px 10px;
  font-size: initial;
}:global(.form__input--lg) {
  border: none;
  background: transparent;
  font-size: 72px;
  text-align: center;
  border-bottom: 1px solid;
}:global(.form__textarea) {
  height: 13rem;
  resize: vertical;
}:global(.form__radio) {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  user-select: none;
}:global(.form__radio) :global(input) {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}:global(.form__radio) :global(input:checked) ~ :global(.form__radio-checkmark) {
  background-color: #132b39;
}:global(.form__radio) :global(input:checked) ~ :global(.form__radio-checkmark:after) {
  display: block;
}:global(.form__radio-checkmark:after) {
  content: "";
  position: absolute;
  display: none;
}:global(.form__radio-checkmark) {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background-color: black;
  border-radius: 50%;
}:global(.form__radio:hover) :global(.form__radio-checkmark) {
  background-color: black;
}:global(.form__label) {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.625rem;
}:global(.form__field-msg) {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.625rem;
  margin-top: 0.25rem;
}:global(.form__validation) {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.625rem;
}:global(.form__error) {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.625rem;
  margin-bottom: 0;
  margin-top: 1.25rem;
}:global(.form__footer) {
  display: block;
  margin-top: 2rem;
}@keyframes -global-pulse {
  0% {
    opacity: 0;
    transform: translateX(-5px);
  }
  50% {
    opacity: 1;
    transform: translateX(5px);
  }
  100% {
    opacity: 0;
    transform: translateX(-5px);
  }
}:global(.loader) {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}:global(.loader) :global(span) {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: pulse infinite ease 1s;
  display: inline-block;
  background-color: white;
  margin: 0 4px;
}:global(.loader) :global(span:nth-of-type(2)) {
  animation-delay: 0.3s;
}:global(.loader) :global(span:nth-of-type(3)) {
  animation-delay: 0.6s;
}:global(.loader--black) :global(span) {
  background-color: black;
}:global(.button) {
  position: relative;
  border: none;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  padding: 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
  appearance: none;
  opacity: 1;
  transition: opacity 0.75s;
}:global(.button--sm) {
  padding: 0.75rem;
}:global(.button--rounded) {
  border-radius: 20px;
  padding: 0.75rem 2rem;
}:global(.button--secondary:hover) {
  opacity: 0.7;
}:global(.button:disabled) {
  opacity: 0.6;
  cursor: not-allowed;
}:global(.button--link) {
  padding: 0;
}:global(.button--link) :global(a) {
  display: block;
  width: 100%;
  padding: 1rem;
}:global(.button--ghost) {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: unset;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  font: inherit;
  color: inherit;
}:global(.button--ghost:hover), :global(.button--ghost:focus) {
  text-decoration: none;
  box-shadow: none;
}:global(.button--contained) {
  width: unset;
}:global(.page) {
  margin: 2rem auto;
  padding: 0 1rem;
}:global(.page--form-only) {
  max-width: 425px;
  padding: 0;
}:global(.page--md) {
  max-width: 768px;
}:global(.page--lg) {
  max-width: 1200px;
}:global(.modal) {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}:global(.modal) :global(.modal-body) {
  min-width: 19rem;
  max-width: 90%;
}:global(.modal--sm) {
  background-color: white;
  text-align: center;
  max-width: 20rem;
  margin: 0 auto;
  padding: 2rem 1rem;
}:global(.grid) {
  display: flex;
  flex-flow: row wrap;
}:global(.grid-align--center) {
  align-items: center;
}:global(.grid--center) {
  justify-content: center;
}:global(.grid--space-around) {
  justify-content: space-around;
}:global(.grid--space-between) {
  justify-content: space-between;
}:global(.grid--equal-children) > :global(*) {
  flex: 1;
}:global(.align--start) {
  align-self: flex-start;
}:global(.align--center) {
  align-self: center;
}:global(.basis--10) {
  flex-basis: 10%;
}:global(.basis--20) {
  flex-basis: 20%;
}:global(.basis--25) {
  flex-basis: 25%;
}:global(.basis--30) {
  flex-basis: 30%;
}:global(.basis--40) {
  flex-basis: 40%;
}:global(.basis--50) {
  flex-basis: 50%;
}:global(.basis--55) {
  flex-basis: 55%;
}:global(.basis--60) {
  flex-basis: 60%;
}:global(.basis--70) {
  flex-basis: 70%;
}:global(.basis--80) {
  flex-basis: 80%;
}:global(html) {
  font-size: 16px;
}:global(body) {
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.02em;
}:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6) {
  margin-bottom: 1rem;
}:global(.lh) {
  line-height: 1.5rem;
}:global(.lh--1) {
  line-height: 1.1;
}:global(p) {
  margin-bottom: 1rem;
  line-height: 1.5rem;
}:global(.heading--lg) {
  padding-top: 0;
  font-size: 2.5rem;
}@media (min-width: 426px) {
  :global(.heading--lg) {
    padding-top: 2rem;
  }
}:global(.heading--xlg) {
  font-size: 5rem;
}:global(.text--center) {
  text-align: center;
}:global(.text--left) {
  text-align: left;
}:global(.text--right) {
  text-align: right;
}:global(.text--underline) {
  text-decoration: underline;
}:global(.text--uppercase) {
  text-transform: uppercase;
}:global(.text--prewrap) {
  white-space: pre-wrap;
}:global(.text--nowrap) {
  white-space: nowrap;
}:global(.text--break) {
  word-break: break-all;
}:global(.text--ellipsis) {
  text-overflow: ellipsis;
  overflow: hidden;
}:global(.text--link) {
  cursor: pointer;
  font-weight: 500;
}:global(.text--white) {
  color: white;
}:global(.text--lg) {
  font-size: 1.2rem;
}@media (min-width: 426px) {
  :global(.text--lg-sm) {
    font-size: 1.2rem;
  }
}:global(.text--xlg) {
  font-size: 3rem;
}:global(.text--md) {
  font-size: 1rem;
}:global(.text--sm) {
  font-size: 0.95rem;
}:global(.text--xs) {
  font-size: 0.8rem;
}:global(.text--thin) {
  font-weight: 100;
}:global(.text--light) {
  font-weight: 300;
}:global(.text--regular) {
  font-weight: 400;
}:global(.text--bold) {
  font-weight: bold;
}:global(.icon-placeholder) {
  padding: 12px;
  width: 0;
  display: inline-block;
  background-color: #efefef;
  vertical-align: middle;
  border-radius: 30%;
}:global(.icon-placeholder--xs) {
  padding: 0.325rem;
}:global(.icon-placeholder--sm) {
  padding: 0.425rem;
}:global(.icon-placeholder--md) {
  padding: 0.75rem;
}:global(.icon-placeholder--lg) {
  padding: 2rem;
}:global(.icon-placeholder--xlg) {
  padding: 3rem;
}:global(.icon--xs) {
  width: 0.65rem;
  height: 0.65rem;
}:global(.icon--sm) {
  width: 0.85rem;
  height: 0.85rem;
}:global(.icon--md) {
  width: 1.5rem;
  height: 1.5rem;
}:global(.icon--lg) {
  width: 4rem;
  height: 4rem;
}:global(.icon--xlg) {
  width: 6rem;
  height: 6rem;
}:global(.display--block) {
  display: block;
}:global(.display--inline-block) {
  display: inline-block;
}:global(.display--inline) {
  display: inline;
}:global(.display--none) {
  display: none;
}:global(.display--flex) {
  display: flex;
}@media (min-width: 426px) {
  :global(.display--sm) {
    display: unset;
  }
}@media (min-width: 769px) {
  :global(.display--md) {
    display: unset;
  }
}:global(.visibility--hidden) {
  visibility: hidden;
}:global(.margin--auto) {
  margin: 0 auto;
}:global(.indicator) {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}@keyframes -global-pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}:global(.pos--bottom) {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}:global(.pos--relative) {
  position: relative;
}:global(.pos--absolute) {
  position: absolute;
}:global(.border-rad--sm) {
  border-radius: 6px;
}:global(.border-rad--30) {
  border-radius: 30%;
}:global(.border-rad--50) {
  border-radius: 50%;
}:global(.width--min) {
  width: 288px;
}:global(.width--50) {
  width: 50%;
}:global(.width--100) {
  width: 100%;
}:global(.height--50) {
  height: 50%;
}:global(.height--100) {
  height: 100%;
}:global(.v-align--middle) {
  vertical-align: middle;
}:global(.v-align--unset) {
  vertical-align: unset;
}:global(.pointer) {
  cursor: pointer;
}:global(.hover--grow) {
  transition: transform ease-in-out 0.25s;
}:global(.hover--grow:hover) {
  transform: rotateZ(15deg);
}:global(.rotate--180) {
  transform: rotate(180deg);
}:global(.heading--massive) {
  font-size: 5rem;
  font-weight: 900;
}@media (min-width: 769px) {
  :global(.heading--massive) {
    font-size: 10rem;
  }
}:global(.cursor--pointer) {
  cursor: pointer;
}:global(.grow) {
  animation: grow 1.5s ease;
}@keyframes -global-grow {
  50% {
    transform: scale(1.1);
  }
}:global(.margin--xs) {
  margin: 0.25rem;
}:global(.margin--none) {
  margin: 0;
}:global(.margin-v--xs) {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}:global(.margin-h--xs) {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}:global(.margin--sm) {
  margin: 0.5rem;
}:global(.margin--none) {
  margin: 0;
}:global(.margin-v--sm) {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}:global(.margin-h--sm) {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}:global(.margin--md) {
  margin: 1rem;
}:global(.margin--none) {
  margin: 0;
}:global(.margin-v--md) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}:global(.margin-h--md) {
  margin-left: 1rem;
  margin-right: 1rem;
}:global(.margin--lg) {
  margin: 1.25rem;
}:global(.margin--none) {
  margin: 0;
}:global(.margin-v--lg) {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}:global(.margin-h--lg) {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}:global(.margin--xlg) {
  margin: 2rem;
}:global(.margin--none) {
  margin: 0;
}:global(.margin-v--xlg) {
  margin-top: 2rem;
  margin-bottom: 2rem;
}:global(.margin-h--xlg) {
  margin-left: 2rem;
  margin-right: 2rem;
}:global(.margin-t--none) {
  margin-top: 0;
}:global(.margin-t--xs) {
  margin-top: 0.25rem;
}@media (min-width: 426px) {
  :global(.margin-t--xs-sm) {
    margin-top: 0.25rem;
  }
}:global(.margin-t--sm) {
  margin-top: 0.5rem;
}@media (min-width: 426px) {
  :global(.margin-t--sm-sm) {
    margin-top: 0.5rem;
  }
}:global(.margin-t--md) {
  margin-top: 1rem;
}@media (min-width: 426px) {
  :global(.margin-t--md-sm) {
    margin-top: 1rem;
  }
}:global(.margin-t--lg) {
  margin-top: 1.25rem;
}@media (min-width: 426px) {
  :global(.margin-t--lg-sm) {
    margin-top: 1.25rem;
  }
}:global(.margin-t--xlg) {
  margin-top: 2rem;
}@media (min-width: 426px) {
  :global(.margin-t--xlg-sm) {
    margin-top: 2rem;
  }
}:global(.margin-b--none) {
  margin-bottom: 0;
}:global(.margin-b--xs) {
  margin-bottom: 0.25rem;
}@media (min-width: 426px) {
  :global(.margin-b--xs-sm) {
    margin-bottom: 0.25rem;
  }
}:global(.margin-b--sm) {
  margin-bottom: 0.5rem;
}@media (min-width: 426px) {
  :global(.margin-b--sm-sm) {
    margin-bottom: 0.5rem;
  }
}:global(.margin-b--md) {
  margin-bottom: 1rem;
}@media (min-width: 426px) {
  :global(.margin-b--md-sm) {
    margin-bottom: 1rem;
  }
}:global(.margin-b--lg) {
  margin-bottom: 1.25rem;
}@media (min-width: 426px) {
  :global(.margin-b--lg-sm) {
    margin-bottom: 1.25rem;
  }
}:global(.margin-b--xlg) {
  margin-bottom: 2rem;
}@media (min-width: 426px) {
  :global(.margin-b--xlg-sm) {
    margin-bottom: 2rem;
  }
}:global(.margin-l--none) {
  margin-left: 0;
}:global(.margin-l--xs) {
  margin-left: 0.25rem;
}@media (min-width: 426px) {
  :global(.margin-l--xs-sm) {
    margin-left: 0.25rem;
  }
}:global(.margin-l--sm) {
  margin-left: 0.5rem;
}@media (min-width: 426px) {
  :global(.margin-l--sm-sm) {
    margin-left: 0.5rem;
  }
}:global(.margin-l--md) {
  margin-left: 1rem;
}@media (min-width: 426px) {
  :global(.margin-l--md-sm) {
    margin-left: 1rem;
  }
}:global(.margin-l--lg) {
  margin-left: 1.25rem;
}@media (min-width: 426px) {
  :global(.margin-l--lg-sm) {
    margin-left: 1.25rem;
  }
}:global(.margin-l--xlg) {
  margin-left: 2rem;
}@media (min-width: 426px) {
  :global(.margin-l--xlg-sm) {
    margin-left: 2rem;
  }
}:global(.margin-r--none) {
  margin-right: 0;
}:global(.margin-r--xs) {
  margin-right: 0.25rem;
}@media (min-width: 426px) {
  :global(.margin-r--xs-sm) {
    margin-right: 0.25rem;
  }
}:global(.margin-r--sm) {
  margin-right: 0.5rem;
}@media (min-width: 426px) {
  :global(.margin-r--sm-sm) {
    margin-right: 0.5rem;
  }
}:global(.margin-r--md) {
  margin-right: 1rem;
}@media (min-width: 426px) {
  :global(.margin-r--md-sm) {
    margin-right: 1rem;
  }
}:global(.margin-r--lg) {
  margin-right: 1.25rem;
}@media (min-width: 426px) {
  :global(.margin-r--lg-sm) {
    margin-right: 1.25rem;
  }
}:global(.margin-r--xlg) {
  margin-right: 2rem;
}@media (min-width: 426px) {
  :global(.margin-r--xlg-sm) {
    margin-right: 2rem;
  }
}:global(.padding--xs) {
  padding: 0.25rem;
}:global(.padding--none) {
  padding: 0;
}:global(.padding-v--xs) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}:global(.padding-h--xs) {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}:global(.padding--sm) {
  padding: 0.5rem;
}:global(.padding--none) {
  padding: 0;
}:global(.padding-v--sm) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}:global(.padding-h--sm) {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}:global(.padding--md) {
  padding: 1rem;
}:global(.padding--none) {
  padding: 0;
}:global(.padding-v--md) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}:global(.padding-h--md) {
  padding-left: 1rem;
  padding-right: 1rem;
}:global(.padding--lg) {
  padding: 1.25rem;
}:global(.padding--none) {
  padding: 0;
}:global(.padding-v--lg) {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}:global(.padding-h--lg) {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}:global(.padding--xlg) {
  padding: 2rem;
}:global(.padding--none) {
  padding: 0;
}:global(.padding-v--xlg) {
  padding-top: 2rem;
  padding-bottom: 2rem;
}:global(.padding-h--xlg) {
  padding-left: 2rem;
  padding-right: 2rem;
}:global(.padding-t--none) {
  padding-top: 0;
}:global(.padding-t--xs) {
  padding-top: 0.25rem;
}@media (min-width: 426px) {
  :global(.padding-t--xs-sm) {
    padding-top: 0.25rem;
  }
}:global(.padding-t--sm) {
  padding-top: 0.5rem;
}@media (min-width: 426px) {
  :global(.padding-t--sm-sm) {
    padding-top: 0.5rem;
  }
}:global(.padding-t--md) {
  padding-top: 1rem;
}@media (min-width: 426px) {
  :global(.padding-t--md-sm) {
    padding-top: 1rem;
  }
}:global(.padding-t--lg) {
  padding-top: 1.25rem;
}@media (min-width: 426px) {
  :global(.padding-t--lg-sm) {
    padding-top: 1.25rem;
  }
}:global(.padding-t--xlg) {
  padding-top: 2rem;
}@media (min-width: 426px) {
  :global(.padding-t--xlg-sm) {
    padding-top: 2rem;
  }
}:global(.padding-b--none) {
  padding-bottom: 0;
}:global(.padding-b--xs) {
  padding-bottom: 0.25rem;
}@media (min-width: 426px) {
  :global(.padding-b--xs-sm) {
    padding-bottom: 0.25rem;
  }
}:global(.padding-b--sm) {
  padding-bottom: 0.5rem;
}@media (min-width: 426px) {
  :global(.padding-b--sm-sm) {
    padding-bottom: 0.5rem;
  }
}:global(.padding-b--md) {
  padding-bottom: 1rem;
}@media (min-width: 426px) {
  :global(.padding-b--md-sm) {
    padding-bottom: 1rem;
  }
}:global(.padding-b--lg) {
  padding-bottom: 1.25rem;
}@media (min-width: 426px) {
  :global(.padding-b--lg-sm) {
    padding-bottom: 1.25rem;
  }
}:global(.padding-b--xlg) {
  padding-bottom: 2rem;
}@media (min-width: 426px) {
  :global(.padding-b--xlg-sm) {
    padding-bottom: 2rem;
  }
}:global(.padding-l--none) {
  padding-left: 0;
}:global(.padding-l--xs) {
  padding-left: 0.25rem;
}@media (min-width: 426px) {
  :global(.padding-l--xs-sm) {
    padding-left: 0.25rem;
  }
}:global(.padding-l--sm) {
  padding-left: 0.5rem;
}@media (min-width: 426px) {
  :global(.padding-l--sm-sm) {
    padding-left: 0.5rem;
  }
}:global(.padding-l--md) {
  padding-left: 1rem;
}@media (min-width: 426px) {
  :global(.padding-l--md-sm) {
    padding-left: 1rem;
  }
}:global(.padding-l--lg) {
  padding-left: 1.25rem;
}@media (min-width: 426px) {
  :global(.padding-l--lg-sm) {
    padding-left: 1.25rem;
  }
}:global(.padding-l--xlg) {
  padding-left: 2rem;
}@media (min-width: 426px) {
  :global(.padding-l--xlg-sm) {
    padding-left: 2rem;
  }
}:global(.padding-r--none) {
  padding-right: 0;
}:global(.padding-r--xs) {
  padding-right: 0.25rem;
}@media (min-width: 426px) {
  :global(.padding-r--xs-sm) {
    padding-right: 0.25rem;
  }
}:global(.padding-r--sm) {
  padding-right: 0.5rem;
}@media (min-width: 426px) {
  :global(.padding-r--sm-sm) {
    padding-right: 0.5rem;
  }
}:global(.padding-r--md) {
  padding-right: 1rem;
}@media (min-width: 426px) {
  :global(.padding-r--md-sm) {
    padding-right: 1rem;
  }
}:global(.padding-r--lg) {
  padding-right: 1.25rem;
}@media (min-width: 426px) {
  :global(.padding-r--lg-sm) {
    padding-right: 1.25rem;
  }
}:global(.padding-r--xlg) {
  padding-right: 2rem;
}@media (min-width: 426px) {
  :global(.padding-r--xlg-sm) {
    padding-right: 2rem;
  }
}/**
  const SMALL = 425;
  const MEDIUM = 768;
  const LARGE = 1200;
*/:global(.message) {
  box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  position: fixed;
  right: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.975);
  z-index: 999;
  width: 300px;
  left: calc(50% - 150px);
  padding: 5%;
  border-radius: 2px;
}@media (min-width: 426px) {
  :global(.message) {
    left: unset;
    border-radius: 5px;
    padding: 25px;
  }
}:global(.message--go) {
  opacity: 0;
  top: -100px;
  animation: notification 10s;
}:global(.message--stay) {
  opacity: 1;
  top: 20px;
  animation: notification 10s;
}@keyframes -global-notification {
  0% {
    top: -100px;
    opacity: 0;
  }
  7%, 95% {
    opacity: 1;
    top: 20px;
  }
}@charset "UTF-8";:global(.react-datepicker__year-read-view--down-arrow),
:global(.react-datepicker__month-read-view--down-arrow),
:global(.react-datepicker__month-year-read-view--down-arrow),
:global(.react-datepicker-popper[data-placement^=top]) :global(.react-datepicker__triangle),
:global(.react-datepicker-popper[data-placement^=bottom]) :global(.react-datepicker__triangle) {
  display: none;
  margin-left: -8px;
  position: absolute;
}:global(.react-datepicker__year-read-view--down-arrow),
:global(.react-datepicker__month-read-view--down-arrow),
:global(.react-datepicker__month-year-read-view--down-arrow),
:global(.react-datepicker-popper[data-placement^=top]) :global(.react-datepicker__triangle),
:global(.react-datepicker-popper[data-placement^=bottom]) :global(.react-datepicker__triangle),
:global(.react-datepicker__year-read-view--down-arrow::before),
:global(.react-datepicker__month-read-view--down-arrow::before),
:global(.react-datepicker__month-year-read-view--down-arrow::before),
:global(.react-datepicker-popper[data-placement^=top]) :global(.react-datepicker__triangle::before),
:global(.react-datepicker-popper[data-placement^=bottom]) :global(.react-datepicker__triangle::before) {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}:global(.react-datepicker__year-read-view--down-arrow::before),
:global(.react-datepicker__month-read-view--down-arrow::before),
:global(.react-datepicker__month-year-read-view--down-arrow::before),
:global(.react-datepicker-popper[data-placement^=top]) :global(.react-datepicker__triangle::before),
:global(.react-datepicker-popper[data-placement^=bottom]) :global(.react-datepicker__triangle::before) {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #f0f3f5;
}:global(.react-datepicker-popper[data-placement^=bottom]) :global(.react-datepicker__triangle) {
  top: 0;
  margin-top: -8px;
}:global(.react-datepicker-popper[data-placement^=bottom]) :global(.react-datepicker__triangle), :global(.react-datepicker-popper[data-placement^=bottom]) :global(.react-datepicker__triangle::before) {
  border-top: none;
  border-bottom-color: #fff;
}:global(.react-datepicker-popper[data-placement^=bottom]) :global(.react-datepicker__triangle::before) {
  top: -1px;
  border-bottom-color: #f0f3f5;
}:global(.react-datepicker__year-read-view--down-arrow),
:global(.react-datepicker__month-read-view--down-arrow),
:global(.react-datepicker__month-year-read-view--down-arrow),
:global(.react-datepicker-popper[data-placement^=top]) :global(.react-datepicker__triangle) {
  bottom: 0;
  margin-bottom: -8px;
}:global(.react-datepicker__year-read-view--down-arrow),
:global(.react-datepicker__month-read-view--down-arrow),
:global(.react-datepicker__month-year-read-view--down-arrow),
:global(.react-datepicker-popper[data-placement^=top]) :global(.react-datepicker__triangle),
:global(.react-datepicker__year-read-view--down-arrow::before),
:global(.react-datepicker__month-read-view--down-arrow::before),
:global(.react-datepicker__month-year-read-view--down-arrow::before),
:global(.react-datepicker-popper[data-placement^=top]) :global(.react-datepicker__triangle::before) {
  border-bottom: none;
  border-top-color: #fff;
}:global(.react-datepicker__year-read-view--down-arrow::before),
:global(.react-datepicker__month-read-view--down-arrow::before),
:global(.react-datepicker__month-year-read-view--down-arrow::before),
:global(.react-datepicker-popper[data-placement^=top]) :global(.react-datepicker__triangle::before) {
  bottom: -1px;
  border-top-color: #f0f3f5;
}:global(.react-datepicker-wrapper) {
  display: block;
  padding: 0;
  border: 0;
}:global(.react-datepicker) {
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #f0f3f5;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.06);
}:global(.react-datepicker--time-only) :global(.react-datepicker__triangle) {
  left: 35px;
}:global(.react-datepicker--time-only) :global(.react-datepicker__time-container) {
  border-left: 0;
}:global(.react-datepicker--time-only) :global(.react-datepicker__time) {
  border-radius: 0.3rem;
}:global(.react-datepicker--time-only) :global(.react-datepicker__time-box) {
  border-radius: 0.3rem;
}:global(.react-datepicker__triangle) {
  position: absolute;
  left: 50px;
}:global(.react-datepicker-popper) {
  z-index: 1;
}:global(.react-datepicker-popper[data-placement^=bottom]) {
  margin-top: 0;
}:global(.react-datepicker-popper[data-placement=bottom-end]) :global(.react-datepicker__triangle), :global(.react-datepicker-popper[data-placement=top-end]) :global(.react-datepicker__triangle) {
  left: auto;
  right: 50px;
}:global(.react-datepicker-popper[data-placement^=top]) {
  margin-bottom: 10px;
}:global(.react-datepicker-popper[data-placement^=right]) {
  margin-left: 8px;
}:global(.react-datepicker-popper[data-placement^=right]) :global(.react-datepicker__triangle) {
  left: auto;
  right: 42px;
}:global(.react-datepicker-popper[data-placement^=left]) {
  margin-right: 8px;
}:global(.react-datepicker-popper[data-placement^=left]) :global(.react-datepicker__triangle) {
  left: 42px;
  right: auto;
}:global(.react-datepicker__header) {
  text-align: center;
  background-color: rgba(0, 0, 255, 0.8);
  border-bottom: 1px solid #f0f3f5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: relative;
}:global(.react-datepicker__year-dropdown-container--select),
:global(.react-datepicker__month-dropdown-container--select),
:global(.react-datepicker__month-year-dropdown-container--select),
:global(.react-datepicker__year-dropdown-container--scroll),
:global(.react-datepicker__month-dropdown-container--scroll),
:global(.react-datepicker__month-year-dropdown-container--scroll) {
  display: inline-block;
  margin: 0 2px;
}:global(.react-datepicker__current-month),
:global(.react-datepicker-time__header),
:global(.react-datepicker-year-header) {
  margin-top: 0;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  padding: 1rem;
}:global(.react-datepicker-time__header) {
  display: none;
}:global(.react-datepicker__navigation) {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  outline: none;
  top: 18px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}:global(.react-datepicker__navigation--previous) {
  left: 10px;
  border-right-color: white;
}:global(.react-datepicker__navigation--previous--disabled), :global(.react-datepicker__navigation--previous--disabled:hover) {
  border-right-color: #e6e6e6;
  cursor: default;
}:global(.react-datepicker__navigation--next) {
  right: 10px;
  border-left-color: white;
}:global(.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)) {
  right: 80px;
}:global(.react-datepicker__navigation--next--disabled), :global(.react-datepicker__navigation--next--disabled:hover) {
  border-left-color: #e6e6e6;
  cursor: default;
}:global(.react-datepicker__navigation--years) {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}:global(.react-datepicker__navigation--years-previous) {
  top: 4px;
  border-top-color: #ccc;
}:global(.react-datepicker__navigation--years-previous:hover) {
  border-top-color: #b3b3b3;
}:global(.react-datepicker__navigation--years-upcoming) {
  top: -4px;
  border-bottom-color: #ccc;
}:global(.react-datepicker__navigation--years-upcoming:hover) {
  border-bottom-color: #b3b3b3;
}:global(.react-datepicker__month-container) {
  float: left;
}:global(.react-datepicker__month) {
  margin: 0.4rem;
  text-align: center;
}:global(.react-datepicker__month) :global(.react-datepicker__month-text),
:global(.react-datepicker__month) :global(.react-datepicker__quarter-text) {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}:global(.react-datepicker__input-time-container) {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}:global(.react-datepicker__input-time-container) :global(.react-datepicker-time__caption) {
  display: inline-block;
}:global(.react-datepicker__input-time-container) :global(.react-datepicker-time__input-container) {
  display: inline-block;
}:global(.react-datepicker__input-time-container) :global(.react-datepicker-time__input-container) :global(.react-datepicker-time__input) {
  display: inline-block;
  margin-left: 10px;
}:global(.react-datepicker__input-time-container) :global(.react-datepicker-time__input-container) :global(.react-datepicker-time__input) :global(input) {
  width: 100px;
}:global(.react-datepicker__input-time-container) :global(.react-datepicker-time__input-container) :global(.react-datepicker-time__input) :global(input[type=time]::-webkit-inner-spin-button),
:global(.react-datepicker__input-time-container) :global(.react-datepicker-time__input-container) :global(.react-datepicker-time__input) :global(input[type=time]::-webkit-outer-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}:global(.react-datepicker__input-time-container) :global(.react-datepicker-time__input-container) :global(.react-datepicker-time__input) :global(input[type=time]) {
  -moz-appearance: textfield;
}:global(.react-datepicker__input-time-container) :global(.react-datepicker-time__input-container) :global(.react-datepicker-time__delimiter) {
  margin-left: 5px;
  display: inline-block;
}:global(.react-datepicker__time-container) {
  float: right;
  border-left: 1px solid #f0f3f5;
  width: 100px;
}:global(.react-datepicker__time-container--with-today-button) {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}:global(.react-datepicker__time-container) :global(.react-datepicker__time) {
  position: relative;
  background: white;
}:global(.react-datepicker__time-container) :global(.react-datepicker__time) :global(.react-datepicker__time-box) {
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}:global(.react-datepicker__time-container) :global(.react-datepicker__time) :global(.react-datepicker__time-box) :global(ul.react-datepicker__time-list) {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}:global(.react-datepicker__time-container) :global(.react-datepicker__time) :global(.react-datepicker__time-box) :global(ul.react-datepicker__time-list) :global(li.react-datepicker__time-list-item) {
  padding: 0.8rem;
  white-space: nowrap;
}:global(.react-datepicker__time-container) :global(.react-datepicker__time) :global(.react-datepicker__time-box) :global(ul.react-datepicker__time-list) :global(li.react-datepicker__time-list-item:hover) {
  cursor: pointer;
  background-color: #6666ff;
  font-weight: 600;
}:global(.react-datepicker__time-container) :global(.react-datepicker__time) :global(.react-datepicker__time-box) :global(ul.react-datepicker__time-list) :global(li.react-datepicker__time-list-item--selected) {
  background-color: #cacaca;
  font-weight: bold;
}:global(.react-datepicker__time-container) :global(.react-datepicker__time) :global(.react-datepicker__time-box) :global(ul.react-datepicker__time-list) :global(li.react-datepicker__time-list-item--disabled) {
  display: none;
}:global(.react-datepicker__week-number) {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
}@media screen and (max-width: 374px) {
  :global(.react-datepicker__week-number) {
    margin: 0.2rem;
  }
}@media screen and (min-width: 375px) {
  :global(.react-datepicker__week-number) {
    margin: 0.3rem 0.35rem;
  }
}:global(.react-datepicker__week-number.react-datepicker__week-number--clickable) {
  cursor: pointer;
}:global(.react-datepicker__week-number.react-datepicker__week-number--clickable:hover) {
  border-radius: 3px;
  background-color: #fff;
}:global(.react-datepicker__day-names) {
  white-space: nowrap;
  background-color: white;
  color: #aeaeae;
  text-transform: uppercase;
  font-weight: bold;
}:global(.react-datepicker__week) {
  white-space: nowrap;
  background-color: white;
}:global(.react-datepicker__day-name) {
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
}@media screen and (max-width: 374px) {
  :global(.react-datepicker__day-name) {
    margin: 0.2rem;
  }
}@media screen and (min-width: 375px) {
  :global(.react-datepicker__day-name) {
    margin: 0.3rem 0.35rem;
  }
}:global(.react-datepicker__day),
:global(.react-datepicker__time-name) {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
}@media screen and (max-width: 374px) {
  :global(.react-datepicker__day),
:global(.react-datepicker__time-name) {
    margin: 0.2rem;
  }
}@media screen and (min-width: 375px) {
  :global(.react-datepicker__day),
:global(.react-datepicker__time-name) {
    margin: 0.3rem 0.35rem;
  }
}:global(.react-datepicker__day--outside-month) {
  color: #e1e1e1;
}:global(.react-datepicker__month--selected), :global(.react-datepicker__month--in-selecting-range), :global(.react-datepicker__month--in-range), :global(.react-datepicker__quarter--selected), :global(.react-datepicker__quarter--in-selecting-range), :global(.react-datepicker__quarter--in-range) {
  border-radius: 3px;
  background-color: rgba(0, 0, 255, 0.8);
  color: #fff;
}:global(.react-datepicker__month--selected:hover), :global(.react-datepicker__month--in-selecting-range:hover), :global(.react-datepicker__month--in-range:hover), :global(.react-datepicker__quarter--selected:hover), :global(.react-datepicker__quarter--in-selecting-range:hover), :global(.react-datepicker__quarter--in-range:hover) {
  background-color: rgba(0, 0, 255, 0.8);
}:global(.react-datepicker__month--disabled),
:global(.react-datepicker__quarter--disabled) {
  color: #ccc;
  pointer-events: none;
}:global(.react-datepicker__month--disabled:hover),
:global(.react-datepicker__quarter--disabled:hover) {
  cursor: default;
  background-color: transparent;
}:global(.react-datepicker__day),
:global(.react-datepicker__month-text),
:global(.react-datepicker__quarter-text) {
  cursor: pointer;
  outline: none;
}:global(.react-datepicker__day:hover),
:global(.react-datepicker__month-text:hover),
:global(.react-datepicker__quarter-text:hover) {
  border-radius: 50%;
  background-color: #cacaca;
}:global(.react-datepicker__day--today),
:global(.react-datepicker__month-text--today),
:global(.react-datepicker__quarter-text--today) {
  font-weight: bold;
}:global(.react-datepicker__day--highlighted),
:global(.react-datepicker__month-text--highlighted),
:global(.react-datepicker__quarter-text--highlighted) {
  border-radius: 3px;
  background-color: #3dcc4a;
  color: #fff;
}:global(.react-datepicker__day--highlighted:hover),
:global(.react-datepicker__month-text--highlighted:hover),
:global(.react-datepicker__quarter-text--highlighted:hover) {
  background-color: #32be3f;
}:global(.react-datepicker__day--highlighted-custom-1),
:global(.react-datepicker__month-text--highlighted-custom-1),
:global(.react-datepicker__quarter-text--highlighted-custom-1) {
  color: magenta;
}:global(.react-datepicker__day--highlighted-custom-2),
:global(.react-datepicker__month-text--highlighted-custom-2),
:global(.react-datepicker__quarter-text--highlighted-custom-2) {
  color: green;
}:global(.react-datepicker__day--selected), :global(.react-datepicker__day--in-selecting-range), :global(.react-datepicker__day--in-range), :global(.react-datepicker__month-text--selected), :global(.react-datepicker__month-text--in-selecting-range), :global(.react-datepicker__month-text--in-range), :global(.react-datepicker__quarter-text--selected), :global(.react-datepicker__quarter-text--in-selecting-range), :global(.react-datepicker__quarter-text--in-range) {
  border-radius: 50%;
  background-color: rgba(0, 0, 255, 0.8);
  color: #fff;
}:global(.react-datepicker__day--selected:hover), :global(.react-datepicker__day--in-selecting-range:hover), :global(.react-datepicker__day--in-range:hover), :global(.react-datepicker__month-text--selected:hover), :global(.react-datepicker__month-text--in-selecting-range:hover), :global(.react-datepicker__month-text--in-range:hover), :global(.react-datepicker__quarter-text--selected:hover), :global(.react-datepicker__quarter-text--in-selecting-range:hover), :global(.react-datepicker__quarter-text--in-range:hover) {
  background-color: rgba(0, 0, 255, 0.8), 5%;
}:global(.react-datepicker__day--keyboard-selected),
:global(.react-datepicker__month-text--keyboard-selected),
:global(.react-datepicker__quarter-text--keyboard-selected) {
  border-radius: 50%;
  background-color: rgba(0, 0, 255, 0.8);
  color: #fff;
}:global(.react-datepicker__day--keyboard-selected:hover),
:global(.react-datepicker__month-text--keyboard-selected:hover),
:global(.react-datepicker__quarter-text--keyboard-selected:hover) {
  background-color: rgba(51, 51, 255, 0.8);
}:global(.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range),
:global(.react-datepicker__month-text--in-range),
:global(.react-datepicker__quarter-text--in-range)),
:global(.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range),
:global(.react-datepicker__month-text--in-range),
:global(.react-datepicker__quarter-text--in-range)),
:global(.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range),
:global(.react-datepicker__month-text--in-range),
:global(.react-datepicker__quarter-text--in-range)) {
  background-color: rgba(0, 0, 255, 0.5);
}:global(.react-datepicker__month--selecting-range) :global(.react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range),
:global(.react-datepicker__month-text--in-selecting-range),
:global(.react-datepicker__quarter-text--in-selecting-range)),
:global(.react-datepicker__month--selecting-range) :global(.react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range),
:global(.react-datepicker__month-text--in-selecting-range),
:global(.react-datepicker__quarter-text--in-selecting-range)),
:global(.react-datepicker__month--selecting-range) :global(.react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range),
:global(.react-datepicker__month-text--in-selecting-range),
:global(.react-datepicker__quarter-text--in-selecting-range)) {
  background-color: #fff;
  color: #000;
}:global(.react-datepicker__day--disabled),
:global(.react-datepicker__month-text--disabled),
:global(.react-datepicker__quarter-text--disabled) {
  cursor: default;
  color: #ccc;
}:global(.react-datepicker__day--disabled:hover),
:global(.react-datepicker__month-text--disabled:hover),
:global(.react-datepicker__quarter-text--disabled:hover) {
  background-color: transparent;
}:global(.react-datepicker__month-text.react-datepicker__month--selected:hover), :global(.react-datepicker__month-text.react-datepicker__month--in-range:hover), :global(.react-datepicker__month-text.react-datepicker__quarter--selected:hover), :global(.react-datepicker__month-text.react-datepicker__quarter--in-range:hover), :global(.react-datepicker__quarter-text.react-datepicker__month--selected:hover), :global(.react-datepicker__quarter-text.react-datepicker__month--in-range:hover), :global(.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover), :global(.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover) {
  background-color: rgba(0, 0, 255, 0.8);
}:global(.react-datepicker__month-text:hover),
:global(.react-datepicker__quarter-text:hover) {
  background-color: #fff;
}:global(.react-datepicker__input-container) {
  position: relative;
  display: inline-block;
  width: 100%;
}:global(.react-datepicker__year-read-view),
:global(.react-datepicker__month-read-view),
:global(.react-datepicker__month-year-read-view) {
  border: 1px solid transparent;
  border-radius: 3px;
}:global(.react-datepicker__year-read-view:hover),
:global(.react-datepicker__month-read-view:hover),
:global(.react-datepicker__month-year-read-view:hover) {
  cursor: pointer;
}:global(.react-datepicker__year-read-view:hover) :global(.react-datepicker__year-read-view--down-arrow),
:global(.react-datepicker__year-read-view:hover) :global(.react-datepicker__month-read-view--down-arrow),
:global(.react-datepicker__month-read-view:hover) :global(.react-datepicker__year-read-view--down-arrow),
:global(.react-datepicker__month-read-view:hover) :global(.react-datepicker__month-read-view--down-arrow),
:global(.react-datepicker__month-year-read-view:hover) :global(.react-datepicker__year-read-view--down-arrow),
:global(.react-datepicker__month-year-read-view:hover) :global(.react-datepicker__month-read-view--down-arrow) {
  border-top-color: #b3b3b3;
}:global(.react-datepicker__year-read-view--down-arrow),
:global(.react-datepicker__month-read-view--down-arrow),
:global(.react-datepicker__month-year-read-view--down-arrow) {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}:global(.react-datepicker__year-dropdown),
:global(.react-datepicker__month-dropdown),
:global(.react-datepicker__month-year-dropdown) {
  background-color: #fff;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #f0f3f5;
}:global(.react-datepicker__year-dropdown:hover),
:global(.react-datepicker__month-dropdown:hover),
:global(.react-datepicker__month-year-dropdown:hover) {
  cursor: pointer;
}:global(.react-datepicker__year-dropdown--scrollable),
:global(.react-datepicker__month-dropdown--scrollable),
:global(.react-datepicker__month-year-dropdown--scrollable) {
  height: 150px;
  overflow-y: scroll;
}:global(.react-datepicker__year-option),
:global(.react-datepicker__month-option),
:global(.react-datepicker__month-year-option) {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}:global(.react-datepicker__year-option:first-of-type),
:global(.react-datepicker__month-option:first-of-type),
:global(.react-datepicker__month-year-option:first-of-type) {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}:global(.react-datepicker__year-option:last-of-type),
:global(.react-datepicker__month-option:last-of-type),
:global(.react-datepicker__month-year-option:last-of-type) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}:global(.react-datepicker__year-option:hover),
:global(.react-datepicker__month-option:hover),
:global(.react-datepicker__month-year-option:hover) {
  background-color: #ccc;
}:global(.react-datepicker__year-option:hover) :global(.react-datepicker__navigation--years-upcoming),
:global(.react-datepicker__month-option:hover) :global(.react-datepicker__navigation--years-upcoming),
:global(.react-datepicker__month-year-option:hover) :global(.react-datepicker__navigation--years-upcoming) {
  border-bottom-color: #b3b3b3;
}:global(.react-datepicker__year-option:hover) :global(.react-datepicker__navigation--years-previous),
:global(.react-datepicker__month-option:hover) :global(.react-datepicker__navigation--years-previous),
:global(.react-datepicker__month-year-option:hover) :global(.react-datepicker__navigation--years-previous) {
  border-top-color: #b3b3b3;
}:global(.react-datepicker__year-option--selected),
:global(.react-datepicker__month-option--selected),
:global(.react-datepicker__month-year-option--selected) {
  position: absolute;
  left: 15px;
}:global(.react-datepicker__close-icon) {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}:global(.react-datepicker__close-icon::after) {
  cursor: pointer;
  background-color: rgba(0, 0, 255, 0.8);
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}:global(.react-datepicker__today-button) {
  background: #fff;
  border-top: 1px solid #f0f3f5;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}:global(.react-datepicker__portal) {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}:global(.react-datepicker__portal) :global(.react-datepicker__day-name),
:global(.react-datepicker__portal) :global(.react-datepicker__day),
:global(.react-datepicker__portal) :global(.react-datepicker__time-name) {
  width: 3rem;
  line-height: 3rem;
}@media (max-width: 400px), (max-height: 550px) {
  :global(.react-datepicker__portal) :global(.react-datepicker__day-name),
:global(.react-datepicker__portal) :global(.react-datepicker__day),
:global(.react-datepicker__portal) :global(.react-datepicker__time-name) {
    width: 2rem;
    line-height: 2rem;
  }
}:global(.react-datepicker__portal) :global(.react-datepicker__current-month),
:global(.react-datepicker__portal) :global(.react-datepicker-time__header) {
  font-size: 1.44rem;
}:global(.react-datepicker__portal) :global(.react-datepicker__navigation) {
  border: 0.81rem solid transparent;
}:global(.react-datepicker__portal) :global(.react-datepicker__navigation--previous) {
  border-right-color: #ccc;
}:global(.react-datepicker__portal) :global(.react-datepicker__navigation--previous:hover) {
  border-right-color: #b3b3b3;
}:global(.react-datepicker__portal) :global(.react-datepicker__navigation--previous--disabled), :global(.react-datepicker__portal) :global(.react-datepicker__navigation--previous--disabled:hover) {
  border-right-color: #e6e6e6;
  cursor: default;
}:global(.react-datepicker__portal) :global(.react-datepicker__navigation--next) {
  border-left-color: #ccc;
}:global(.react-datepicker__portal) :global(.react-datepicker__navigation--next:hover) {
  border-left-color: #b3b3b3;
}:global(.react-datepicker__portal) :global(.react-datepicker__navigation--next--disabled), :global(.react-datepicker__portal) :global(.react-datepicker__navigation--next--disabled:hover) {
  border-left-color: #e6e6e6;
  cursor: default;
}/**
  const SMALL = 425;
  const MEDIUM = 768;
  const TABLET = 1023; specific to just this application
  const LARGE = 1200;
*/:global(html) {
  font-size: 16px; }:global(body) {
  font-family: "Lato", sans-serif; }:global(h1) {
  font-size: 3rem;
  font-weight: bold; }:global(.lh) {
  line-height: 1.25rem; }:global(ol) {
  list-style: disc;
  list-style-position: inside; }:global(.text--link) {
  color: #0091ff; }:global(.text--link:hover) {
    text-decoration: underline; }:global(.bg--primary) {
  background-color: #f7b878; }:global(.bg--secondary) {
  background-color: #3c475d; }:global(.bg--tertiary) {
  background-color: #f5d6b8; }:global(.bg--white) {
  background-color: #fff; }:global(.fill--white) {
  fill: white; }:global(.fill--secondary) {
  fill: #3c475d; }:global(.fill--secondary) :global(svg) {
    fill: #3c475d; }:global(.hr) {
  background-color: #f7b878;
  max-width: 8rem;
  height: 2px; }:global(.profile-image) {
  height: 250px;
  width: 250px; }:global(.border--b) {
  padding-bottom: 10px;
  border-bottom: 1px solid #3c475d; }:global(.side) {
  max-width: 320px;
  position: absolute;
  z-index: 1; }@media (min-width: 1024px) {
    :global(.side) {
      position: relative; } }@media (min-width: 1024px) {
    :global(.side) :global(.close) {
      display: none; } }:global(.side) :global(li) {
    padding: 0.35rem 0; }:global(.side) :global(li) :global(a) {
      text-decoration: underline; }:global(.side) :global(.skills) :global(li) {
    background-color: #3c475d;
    color: #fff;
    border-radius: 2px;
    padding: 0.25rem 0.5rem;
    margin: 0 0 0.25rem 0.25rem;
    font-size: 0.8rem; }:global(.side) :global(.skills) :global(li) :global(span) {
      padding-right: 0; }:global(.side) :global(.skills) :global(li) :global(svg) {
      width: 20px;
      height: 20px;
      fill: white; }:global(.side-overlay) {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(254, 252, 251, 0.75); }:global(main) {
  max-width: 1199px; }@media (min-width: 1024px) {
    :global(main) {
      margin: 4rem auto; } }:global(.main) {
  flex: 1; }:global(.menu) {
  animation: grow cubic-bezier(0.65, 0.05, 0.36, 1) 1.5s; }@media (min-width: 1024px) {
    :global(.menu) {
      display: none; } }@keyframes -global-grow {
  0% {
    transform: scale(1.5); }
  50% {
    transform: scale(1.8); } }:global(.content) {
  padding: 2rem; }@media (min-width: 768px) {
    :global(.content) {
      padding: 4rem; } }</style>

<main class="display--flex">
  <Side hide={hideSide} {onSideHide} />
  <Main {onMenuClick} />
  {#if !hideSide && window.innerWidth < 1024}
    <div class="side-overlay" on:click={closeSide} />
  {/if}
</main>
